@import 'color_variables';

table,
th,
td {
  border: 1px solid $greyRGB;
  border-collapse: collapse;
}

table {
  width: 100%;
  tbody {
    tr {
      td {
        padding: 0.5em 0.75em;
        p {
          padding: 0;
          margin: 0;
          span.Bold {
            font-weight: 700;
          }
        }
        ul {
          margin: 0;
        }
      }
    }
  }
}
