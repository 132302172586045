$appraisals: rgb(43, 147, 200);
$appraisalsAlpha: rgba(43, 147, 200, 0.8);
$aroraRGB: rgb(68, 113, 169);
$aroraAlpha: rgba(68, 113, 169, 0.8);
$cancerRGB: rgb(191, 26, 48);
$cancerAlpha: rgba(191, 26, 48, 0.8);
$ccgRGB: rgb(178, 112, 7);
$ccgAlpha: rgba(178, 112, 7, 0.8);
$diabetesRGB: rgb(144, 187, 97);
$diabetesAlpha: rgba(144, 187, 97, 0.8);
$generalRGB: rgb(115, 198, 227);
$generalAlpha: rgba(115, 198, 227, 0.8);
$gpUpdateRGB: rgb(43, 147, 200);
$gpUpdateAlpha: rgba(43, 147, 200, 0.8);
$minorSurgeryRGB: rgb(97, 173, 211);
$minorSurgeryAlpha: rgba(97, 173, 211, 0.8);
$nurseRGB: rgb(200, 43, 95);
$nurseAlpha: rgba(200, 43, 95, 0.8);
$resilienceRGB: rgb(127, 42, 70);
$resilienceAlpha: rgba(127, 42, 70, 0.8);
$traineeRGB: rgb(2, 192, 189);
$traineeAlpha: rgba(2, 192, 189, 0.8);
$urgentCareRGB: rgb(70, 84, 128);
$urgentCareAlpha: rgba(70, 84, 128, 0.8);
$webinarRGB: rgb(245, 166, 35);
$webinarAlpha: rgba(245, 166, 35, 0.8);
$womensHealthRGB: rgb(62, 143, 141);
$dropShadow: rgb(62, 143, 141);
$blackAlpha: rgba(74, 74, 74, 0.8);
$blackRGB: rgb(74, 74, 74);
$whiteAlpha: rgba(255, 255, 255, 0.8);
$whiteRGB: rgb(255, 255, 255);
$greenAlpha: rgba(144, 187, 97, 0.8);
$greenRGB: rgb(144, 187, 97);
$greenAlpha: rgba(144, 187, 97, 0.8);
$skyRGB: rgb(43, 147, 200);
$skyAlpha: rgba(43, 147, 200, 0.8);
$nbBlueAlpha: rgba(11, 70, 100, 0.8);
$nbBlueRGB: rgb(11, 70, 100);
$nbLightBlueRGB: rgb(236, 238, 239);
$nbLightBlueAlpha: rgba(236, 238, 239, 0.8);
$nbHighlightBlueRGB: rgb(90, 134, 155);
$nbHighlightBlueAlpha: rgba(90, 134, 155, 0.8);
$nbLightHighlightBlueRGB: rgb(120, 144, 156);
$nbLightHighlightBlueAlpha: rgba(120, 144, 156, 0.8);
$nbBlueInverseRGB: rgb(2, 69, 101);
$nbBlueInverseAlpha: rgba(2, 69, 101, 0.8);
$nbBlueOutlineRGB: rgb(151, 180, 197);
$nbBlueOutlineAlpha: rgba(151, 180, 197, 0.8);
$nbBlueFooterRGB: rgb(34, 93, 123);
$nbBlueFooterAlpha: rgba(34, 93, 123, 0.8);
$greyRGB: rgb(238, 238, 238);
$greyAlpha: rgba(238, 238, 238, 0.8);
$darkGreyRGB: rgb(204, 204, 204);
$darkGreyAlpha: rgba(204, 204, 204, 0.8);
$redRGB: rgb(255, 0, 0);
$redAlpha: rgba(255, 0, 0, 0.8);
$darkOrangeRGB: rgb(229, 141, 3);
$darkOrangeAlpha: rgba(229, 141, 3, 0.8);
$orangeRGB: rgb(255, 152, 0);
$orangeAlpha: rgba(255, 152, 0, 0.8);
$mentalHealthAlpha: rgba(226, 193, 8, 0.8);
$mentalHealthRGB: rgb(226, 193, 8);
$covidAlpha: rgba(23, 102, 175, 0.8);
$covidRGB: rgb(23, 102, 175);
$theGreenGpRGB: rgb(44, 76, 59);
$theGreenGpAlpha: rgb(44, 76, 59, 0.8);
$fourteenFishRGB: rgb(207, 45, 129);
$abnormalBloodsRGB: rgb(102, 0, 0);
$abnormalBloodsAlpha: rgba(102, 0, 0, 0.8);
$obesityRGB: rgb(220, 12, 220);
$obesityAlpha: rgba(220, 12, 220, 0.8);

$link-color: $nbBlueRGB;
$link-hover-color: $nbBlueRGB;
