@import 'color_variables';
@import 'typography';

$btn-border-radius: 0.25rem;
$primary: $orangeRGB;
$secondary: $nbHighlightBlueRGB;
$light: $greyAlpha;
$input-focus-box-shadow: none;
// $font-family-sans-serif: $body;
// $headings-font-weight: 700;
// $h1-font-size: 2rem;
// $h2-font-size: 1.5rem;
// $h3-font-size: 1.3rem;
// $h4-font-size: 1.2rem;
// $h5-font-size: 1.1rem;
// $h6-font-size: 1rem;
$navbar-dark-color: white;
$navbar-dark-toggler-border-color: transparent;
$list-group-active-bg: lighten($orangeRGB, 30%);
$list-group-active-color: $nbBlueRGB;
$table-color: $nbBlueRGB;
$btn-focus-width: 0;
$nav-pills-link-active-bg: $secondary !default;
$gpUpdate: rgb(43, 147, 200);
$webinar: #f5a623;
$diabetes: rgb(144, 187, 97);
$urgentCare: rgb(70, 84, 128);
$women-s-health: rgb(62, 143, 141);
$cancer: rgb(191, 26, 48);
$clinic: #a5415f;
$resilience: rgb(127, 42, 70);
$sage: rgb(71, 112, 70);
$theme-colors: (
  'gp-first': rgb(63, 104, 127),
  'cancer': $cancer,
  'ccg': #b27007,
  'diabetes': $diabetes,
  'general': rgb(115, 198, 227),
  'hot-topics-gp-update': $gpUpdate,
  'minor-surgery': rgb(97, 173, 211),
  'nurse': rgb(200, 43, 95),
  'resilience': $resilience,
  'gp-trainee': rgb(2, 192, 189),
  'urgent-care': $urgentCare,
  'webinar': $webinar,
  'women-s-health': $women-s-health,
  'appraisal-essentials': $sage,
  'mental-health': rgb(226, 193, 8),
  'covid': rgb(23, 102, 175),
  'clinic': $clinic,
  'the-green-gp': $theGreenGpRGB,
  'abnormal-bloods': $abnormalBloodsRGB,
  'obesity': $obesityRGB,
);

$colors: ();
$enable-shadows: true;
$enable-gradients: true;
$yiq-contrasted-threshold: 180;
$enable-rounded: false;
$border-width: 1px;
$input-btn-border-width: 1px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
// $font-size-base: 0.8rem;
// @media only screen and (max-device-width: 320px) {
//   html,
//   body {
//     font-size: 14px !important;
//   }
// }
