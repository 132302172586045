@import 'color_variables';
$transitionTime: 0.15s;

.toggle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .switch {
    margin-left: auto;
  }

  input {
    &:checked + .slider {
      background-color: lighten($greenRGB, 10%);

      &:before {
        -webkit-transform: translateX(1.9rem);
        -ms-transform: translateX(1.9rem);
        transform: translateX(1.9rem);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px white;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 2rem;
    padding-bottom: 0 !important;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $greyRGB;
    border: 1px solid $greyRGB;
    -webkit-transition: $transitionTime;
    transition: $transitionTime;

    &:before {
      position: absolute;
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      left: 0.2rem;
      bottom: 0.2rem;
      background-color: $orangeAlpha;
      -webkit-transition: $transitionTime;
      transition: $transitionTime;
    }
  }

  .round {
    border-radius: 2rem;

    &:before {
      border-radius: 50%;
    }
  }
}
