/* You can add global styles to this file, and also import other style files */
@import 'color_variables';
@import 'typography';
@import 'variables';

@import '~bootstrap/scss/bootstrap';

// $fa-font-path: 'node_modules/@fortawesome/fontawesome-free/webfonts';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';

@import url('https://use.typekit.net/dri8rgi.css');
// @import url('https://use.typekit.net/kel5jib.css');

@import 'content_type';
// @import 'buttons';
@import 'modals';
@import 'toggle';
@import 'tables';
@import 'topic_text';

html {
  padding: 0;
  margin: 0;
  height:100%;
}

body {
  // font-size: 16px;

  height: 100%;
  margin: 0;
  color: $nbBlueRGB;
  background-color: white;
}

a {
  color: $nbBlueRGB;
}

*:focus {
  outline: 0;
}

.btn-group-responsive {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn {
    position: relative;
    flex: 1 1 auto;

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    @include hover {
      z-index: 1;
    }
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }

  // Prevent double borders when buttons are next to each other
  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: -$btn-border-width;
  }

  // Reset rounded corners
  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    @include border-right-radius(0);
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > .btn,
    > .btn-group {
      width: 100%;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
      margin-left: 0;
      margin-top: -$btn-border-width;
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      @include border-radius($btn-border-radius);
      @include border-bottom-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-radius($btn-border-radius);
      @include border-top-radius(0);
    }
  }
}
.btn-pdf {
  @include button-variant(rgb(180, 0, 0), darken(rgb(180, 0, 0), 5%));
  // color: white;
  // background-color: rgb(180, 0, 0);
  // &:hover {
  //   color: white;
  // }
}

.btn-excel {
  @include button-variant(rgb(0, 105, 60), darken(rgb(0, 105, 60), 5%));
}

.cursor-pointer {
  cursor: pointer;
}

.success {
  border: 1px solid $greenRGB;
  border-radius: 0.25em;
  color: $greenRGB;
  padding: 0.5em 1em;
  margin: 1em 0;
}

.simple-message {
  border: 1px solid $greyAlpha;
  border-radius: 0.25em;
  padding: 1em;
}

.back_button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  h2 {
    @media only screen and (max-width: 767px) {
      margin-bottom: 0.5em !important;
    }
  }
  span {
    margin-left: auto;
    color: $orangeAlpha;
    text-decoration: none;
    margin-right: 0.25em;
    @media only screen and (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  a {
    color: $nbBlueRGB;
    font-weight: 700;
  }
  @media only screen and (max-width: 767px) {
    text-align: center !important;
    justify-content: center;
    margin-bottom: 0.5em;
  }
}

a.back-button {
  &::before {
    color: $orangeAlpha;
    content: '\00AB\00A0';
  }
}

.maintenance {
  border: 1px solid $redRGB;
  background-color: lighten($redRGB, 20%);
  color: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
}

.simple-search {
  background-color: $greyAlpha;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
  }
}

/*
Account styles
*/

.invalid-feedback.login,
.error.login {
  background-color: rgba(255, 0, 0, 0.4);
  color: white;
  // font-size: 0.75em;
  border: 1px solid $redAlpha;
  padding: 0.5em;
  border-radius: 0.25em;
  margin-left: auto;
}

.account-container {
  .btn {
    @extend .text-uppercase;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1 0 auto;
  padding: 0 1rem;

  width: 90%;
  min-width: 320px * 0.9;
  max-width: 767px;
  .logo {
    max-height: 5rem;
    @extend .mb-5;
  }

  a.link {
    color: white;
  }
  form {
    align-self: center;
    width: 100%;
    @media only screen and (max-width: 414px) {
      min-width: 1px;
    }
  }
}
/*
  NG bootstrap
*/
button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

.ngb-toasts.bottom {
  bottom: 0;
  top: unset;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .btn-footer {
    font-size: 12px;
  }
}

.offer-holder {
  border: 1px solid $greyRGB;
  background-color: transparentize($color: $greyRGB, $amount: 0.75);
}

.kiss-hr {
  width: 175px;
  background-color: orange;
  height: 2px;
  @include media-breakpoint-down(lg) {
    margin: ($spacer) auto;
  }
  margin: (1.5 * $spacer) auto;
}
