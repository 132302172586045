@import 'color_variables';
@import 'typography';

.x-large-modal > .modal-dialog {
  max-width: 75vw;
  height: auto;

  @media only screen and (max-width: 1024px) {
    max-width: 100vw;
  }
}

.large-modal > .modal-dialog {
  max-width: 60vw;
  height: auto;

  @media only screen and (max-width: 1024px) {
    max-width: 100vw;
  }
}

.medium-modal > .modal-dialog {
  max-width: 40vw;
  height: auto;

  @media only screen and (max-width: 1024px) {
    max-width: 100vw;
  }
}

.small-modal > .modal-dialog {
  max-width: 25vw;
  height: auto;

  @media only screen and (max-width: 1024px) {
    max-width: 100vw;
  }
}

.nsm-content {
  border-radius: 0;
  padding: 1em;
  @media only screen and (max-width: 1024px) {
    margin: 2em 0.5em;
  }

  h2 {
    margin: 0;
    @media only screen and (max-width: 767px) {
      // font-size: 1.15em;
      width: 75%;
    }
  }

  .nsm-dialog-btn-close {
    top: 1em;
    right: 1em;
    color: black;
  }

  .buttons {
    display: flex;

    @media only screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  .primary,
  .destroy,
  .secondary {
    padding: 1em 3em;
  }

  .primary,
  .secondary {
    margin-left: auto;
  }

  .destroy {
    margin-left: 1em;
  }

  dl {
    display: flex;
    flex-direction: column;

    dt {
      background-color: $greyAlpha;
      padding: 0.5em 0 0.5em 0.25em;
      font-weight: 700;
    }

    dd {
      margin-left: 0;
      padding: 0.5em 0 0.5em 0.5em;
      display: flex;

      .primary,
      .secondary {
        margin-left: auto;
        margin-right: 1em;
      }

      .google_map_location {
        padding: 1em 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:before {
          content: '';
          float: left;
          width: 1.5em;
          height: 1.5em;
          background-position: center center;
          background-image: url('../assets/imgs/location_icon.svg');
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.75em;
        }
      }
    }
  }
}
