// $body: century-gothic, sans-serif;

$font-family-base: proxima-nova, sans-serif;
$headings-font-family: century-gothic, sans-serif;
// $font-weight-base: 300;
$font-weight-base: 300;
// $font-weight-bold: 600;
// $headings-font-weight: 600;
$font-size-base: 0.9rem;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: -0.03em;
}
